import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FeatureService } from '../../services/feature.service';
import { switchMap, take } from 'rxjs/operators';
import * as $ from 'jquery';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { OneTrustService } from 'projects/dialog/src/app/core/services/one-trust.service';
@Component({
  selector: 'dl-fschart',
  templateUrl: './fschart.component.html',
  styleUrls: ['./fschart.component.scss']
})
export class FschartComponent implements OnInit, AfterViewInit {
  public subs: Subscription[] = [];
  public env: string;
  public countryCode: string = 'CH';
  public languageCode = 'en_us';
  public requestSubject = new Subject<any>();
  public firewallData: any = {};
  public selectedLabIds = [];
  public accounts: any;
  public loading: boolean = true;
  public viewStatusUrl: string;
  constructor(public featureService: FeatureService, public authService: AuthService, public cdr: ChangeDetectorRef, public oneTrustService: OneTrustService) {
    this.initRequestHandler();
  }
  ngAfterViewInit(): void {
    $('.mat-toolbar, #dl-footer').hide();
  }
  public firewallPieData = [];
  public totalFirewallDevices = 0;
  ngOnInit(): void {
    this.oneTrustService.closeCookiePreferences();
    console.log('fsChart onInit called');
    this.languageCode = this.authService?.['countryLanguageService']?.['languageCode'] ? this.authService['countryLanguageService']['languageCode'] : 'en_us';
    this.countryCode = this.authService?.userProfile?.countryCode.toUpperCase();
    console.log('countryCode: ' + this.countryCode);
    // this.viewStatusUrl = window.location.origin + '/dialog/'+ 'gb' + '/' + this.languageCode.toLocaleLowerCase() +'/app-enabler/laboratory';
    this.viewStatusUrl = window.location.origin + '/dialog/'+ this.countryCode.toLocaleLowerCase() + '/' + this.languageCode.toLocaleLowerCase() +'/app-enabler/laboratory';
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
    console.log('fschart.component.ts ngOnInit');
    this.getAccounts();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    console.log('Received message:', event.data);
    if (event.data?.eventType === 'init' && event.data?.eventBody?.launchToken) {
      let token_data = this.decodeToken(event.data?.eventBody?.launchToken);
      // localStorage.setItem('token', event.data?.eventBody?.launchToken);
    }
    if (event.data?.eventType === 'setToken' && event.data?.eventBody?.config?.token) {
      let token_data = this.decodeToken(event.data?.eventBody?.launchToken);
      // localStorage.setItem('token', event.data?.eventBody?.config?.token);
    }
  }

  public decodeToken(token: string) {
    try {
      const payloadBase64 = token.split('.')[1];
      const payloadDecoded = atob(payloadBase64);
      const decodedToken = JSON.parse(payloadDecoded);
      console.log("decodedToken: ",decodedToken);
      return decodedToken;
    } catch (error) {
      console.error('Invalid token');
      return null;
    }
  }

  public getAccounts(): void {
    console.log('fsChart getAccounts called');
    const contactId = `${this.authService.userProfile.contactId}`;
    let url = '';
    console.log('countryCode: ' + this.countryCode);
    // For running on local use below else comment it
    if (this.env !== '') {
      url = `${this.env}/${this.featureService.msUrl}/${this.countryCode}/users/${contactId}/`;
    } else {
      url = `/${this.featureService.msUrl}/CH/users/CON-0003431477/`;
    }

    this.accounts = {};
    this.subs.push(
      this.featureService.getAccounts(url).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.accounts = response?.response?.response?.content?.accounts;
            if (this.accounts['licensed'].length > 0) {
              this.accounts['licensed'].forEach((item, index) => {
                this.selectedLabIds.push(item.crmNumber);
              });
            }
            if (this.selectedLabIds.length == 0 && this.accounts['non-licensed'].length > 0) {
              this.accounts['non-licensed'].forEach((item, index) => {
                this.selectedLabIds.push(item.crmNumber);
              });
            }
            this.getFirewallStatus(this.selectedLabIds);
          } else {
            // code to show message box
            this.featureService.notify(response, 'error');
          }
        },
        error => {
          console.log('getAccounts error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }

  public initRequestHandler() {
    console.log('fsChart initRequestHandler called');
    this.requestSubject.pipe(switchMap(labIds => this.featureService.getFirewallStatus(this.getUrl(), labIds, false))).subscribe(
      (response: any) => {
        if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
          this.firewallData = response?.response?.data; // Main firewall status data
          this.totalFirewallDevices = Object.keys(this.firewallData).length;
          this.setFirewallPieData(this.firewallData);
        } else {
          this.firewallData = {};
          this.featureService.notify(response, 'error');
          this.featureService.firewallData.next({});
        }
      },
      (error: any) => {
        console.log('getFirewallStatus: ' + error);
        this.featureService.notify(error?.error ? error.error : error, 'error');
        this.featureService.firewallData.next({});
      }
    );
  }

  public getUrl(): string {
    return `${this.env}/${this.featureService.fnUrl}/${this.countryCode}/firewall/status/`;
  }

  public getFirewallStatus(labIds: any): void {
    this.firewallData = {};
    console.log('fsChart getFirewallStatus called');
    this.requestSubject.next(labIds);
  }

  public setFirewallPieData(firewallData: any): void {
    this.firewallPieData = [
      { name: $localize`:@@firewall-tile-configured-connected:Configured & Connected`, value: 0, color: 'rgb(0, 124, 100)' },
      { name: $localize`:@@firewall-tile-configured-notconnected:Configured & Not connected`, value: 0, color: 'rgb(4, 188, 162)' },
      { name: $localize`:@@firewall-tile-notconfigured:Not configured`, value: 0, color: 'rgb(176, 202, 162)' },
      { name: $localize`:@@firewall-tile-not-found-at-backend:Not found at backend`, value: 0, color: 'rgba(0, 145, 140, 0.26)' }
    ];

    for (const value of Object.values(firewallData)) {
      this.incrementPieData(value);
    }
  }

  public incrementPieData(value: any): void {
    this.loading = false;
    if (value?.conf_status === 'insync' && value?.conn_status === 'up') {
      this.firewallPieData[0].value += 1;
    } else if (value?.conf_status === 'insync' && value?.conn_status !== 'up') {
      this.firewallPieData[1].value += 1;
    } else if (value?.conf_status && value?.conf_status !== 'insync') {
      this.firewallPieData[2].value += 1;
    } else {
      this.firewallPieData[3].value += 1;
    }
  }
}
