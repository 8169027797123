import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Components } from '@one/web-components';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../common/popup/popup.component';
import { SupportTicketComponent } from '../support-ticket/support-ticket.component';
import { debounceTime, filter, take } from 'rxjs/operators';
import * as $ from 'jquery';

interface Account {
  account_name: string;
  account_number: string;
  account_primary: string;
  crmNumber: string;
  erpNumber: string;
  sales_org: string;
  license: {
    Firewall: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
    Vulnerabilities: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
    Advisories: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
    expired: boolean;
    SoC: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
  };
}

@Component({
  selector: 'dl-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('iconElement', { static: true }) private iconElement: Components.OwcIcon | undefined;
  @ViewChild('buttonElement', { static: true }) private buttonElement!: Components.OwcButton;
  @ViewChild('tableElement', { static: true }) public tableElement: Components.OwcTable;

  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild('expandableGroupElement', { static: true }) private expandableGroupElement: Components.OwcExpandableGroup;
  @ViewChild('paginationElement', { static: true }) public paginationElement: Components.OwcPagination;

  public devicesExpanded: boolean;
  public firewallExpanded: boolean;
  public page: number;
  public total: number;
  public rexisDevicesCount: number;
  public s3DevicesCount: number;
  public rowsOptions: any;
  public rowsPerPage: number;
  public updatedDeviceDetails: any = [];
  public paginatedDevices: any;
  public dummyDeviceDetails: any;
  public firewallData: any;
  public countryCode = '';
  public showHideView: boolean;
  public editVersion: boolean;
  public allDevicesHidden = false;
  public invisibleCount = 0;
  public selAccountData: any;
  private pageChange = new Subject<object>();
  public isDisabled = false;
  public env: string;
  public isLoggedIn = this.authService.isLoggedIn;
  public downloadReportTitle: string;
  public generateReportTitle: string;
  public checkConnectivity: string;
  public updateVersion: string;
  public registerFirewall: string;
  public requestFirewall: string;
  public titleToggleDevices: string;
  public loading: boolean;
  public multiLabNameObj: any;
  private readonly debounceTimeMs = 1000; // Set the debounce time (in milliseconds)

  public showOnlyFirewall: number;
  public subs: Subscription[] = [];
  public firewallLicense: number;
  public vulnerabilityLicence: number;
  public socLicence: number;
  public licenseExpired: number;
  public socAndCombinedLicense: number;
  public intervalId: any;
  public pageParams: any;
  private prevurl: string;
  private LAB_DETAIL_PAGE: string;
  public selectedLabIds: any;

  public constructor(private router: Router, private featureService: FeatureService, private ref: ChangeDetectorRef, private authService: AuthService, public dialog: MatDialog) {
    // this.prevurl =  this.router.getCurrentNavigation().previousNavigation.finalUrl.toString()
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      console.log('previous url: ', this.router.getCurrentNavigation().previousNavigation.finalUrl.toString());
      this.prevurl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
    });
  }

  public ngOnDestroy(): void {
    const menu = document.querySelector('owc-menu');
    if (menu) {
      menu.remove();
    }
    this.subs.forEach(sub => sub.unsubscribe());
    clearInterval(this.intervalId);
  }

  public ngAfterViewInit(): void {
    let controls;
    controls = $('#tabsElement').find('owc-tab');
    $(controls[1]).trigger('click');
    this.ref.markForCheck();
  }

  public ngOnInit(): void {
    this.loading = false;
    this.LAB_DETAIL_PAGE = '/app-enabler/lab-detail';
    localStorage.removeItem('selectedLab');
    this.downloadReportTitle = $localize`:@@downloadReportTitle:Download 360 degree report`;
    this.generateReportTitle = $localize`:@@generateReportTitle:Generate 360 degree report`;
    this.checkConnectivity = $localize`:@@check-connectivity:Check connectivity`;
    this.updateVersion = $localize`:@@update-version: Please check the installed version information`;
    this.registerFirewall = $localize`:@@register-firewall:Register firewall`;
    this.requestFirewall = $localize`:@@request-firewall:Request firewall`;
    this.titleToggleDevices = $localize`:@@title-toggle-devices:Show/hide devices`;
    this.devicesExpanded = false;
    this.firewallExpanded = true;
    this.paginatedDevices = [];
    this.page = 1;
    this.rowsPerPage = 10;
    this.total = 1;
    this.rowsOptions = [10, 20, 25];
    this.firewallData = {};
    this.rexisDevicesCount = 0;
    this.s3DevicesCount = 0;
    this.editVersion = false;
    this.showHideView = false;
    this.selectedLabIds = [];

    this.pageParams = { page: 1, rowsPerPage: 10, total: 1, rexisDevicesCount: this.rexisDevicesCount, s3DevicesCount: this.s3DevicesCount };

    this.subs.push(
      this.featureService.firewallExpanded.subscribe(firewallExpanded => {
        this.firewallExpanded = firewallExpanded;
        this.devicesExpanded = !this.firewallExpanded;
      })
    );

    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );

    this.subs.push(
      this.featureService.countryCode.subscribe(countryCode => {
        this.countryCode = countryCode;
      })
    );

    this.subs.push(
      this.featureService.paginatedDevices.subscribe((devices: any) => {
        this.paginatedDevices = devices; // All devices data to show in "All devices" section on UI
        this.dummyDeviceDetails = JSON.parse(JSON.stringify(this.paginatedDevices));
      })
    );
    this.subs.push(
      this.featureService.devicePageParams.subscribe((pageParams: any) => {
        this.pageParams = pageParams;
        this.page = pageParams.page;
        this.rowsPerPage = pageParams.rowsPerPage;
        this.total = pageParams.total;
        this.rexisDevicesCount = pageParams.rexisDevicesCount;
        this.s3DevicesCount = pageParams.s3DevicesCount;
      })
    );

    this.subs.push(
      this.featureService.selectedLab.pipe(take(1)).subscribe(
        (selAccountData: Account[]) => {
          if (selAccountData.length > 1) {
            this.multiLabNameObj = {};
            selAccountData.map(lab => {
              this.multiLabNameObj[lab.crmNumber] = { labName: lab.account_name, labId: lab.crmNumber };
            });
            this.selectedLabIds = selAccountData.map(lab => lab.crmNumber);
            this.selAccountData = selAccountData;
            if (selAccountData.length > 1 || this.prevurl.indexOf(this.LAB_DETAIL_PAGE) === -1 || this.paginatedDevices.length === 0) {
              this.getDevices({ page: 1, rowsPerPage: 10, total: 1, rexisDevicesCount: this.rexisDevicesCount, s3DevicesCount: this.s3DevicesCount });
              this.getFirewallStatus(this.selectedLabIds, true); // true for getting report status from DB
            }
          } else if (selAccountData.length === 1) {
            this.selectedLabIds = selAccountData.map(lab => lab.crmNumber);
            this.selAccountData = selAccountData[0]; // selected laboratory name in dropdown
            this.getLicenses();
          }
        },
        error => {
          console.log('selectedLab: ' + error);
        }
      )
    );

    this.pageChange.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
      this.pageParams = { page: this.page, rowsPerPage: this.rowsPerPage, total: this.total, rexisDevicesCount: this.rexisDevicesCount, s3DevicesCount: this.s3DevicesCount };
      this.getDevices(this.pageParams);
    });

    this.subs.push(
      this.featureService.invisibleCount.subscribe((invisibleCount: number) => {
        this.invisibleCount = invisibleCount;
      })
    );
    this.subs.push(
      this.featureService.firewallData.subscribe((fd: any) => {
        this.firewallData = fd; // "Firewall status" section Data to show on UI
      })
    );
    this.intervalId = this.intervalId ? clearInterval(this.intervalId) : '';
    if ((this.socLicence || this.firewallLicense) && (this.prevurl.indexOf(this.LAB_DETAIL_PAGE) === -1 || this.isObjectEmpty())) {
      const labIds = this.selAccountData.length > 1 ? this.selAccountData.map(lab => lab.crmNumber) : this.selAccountData.crmNumber;
      this.getFirewallStatus(labIds, true);
    }

    // if (!(this.socLicence === 1 || this.firewallLicense === 1)) {
    //   this.devicesExpanded = true;
    // }
  }

  public pageChangeHandler(event: any): void {
    /* condition added to avoid multiple requests on page load and on next/pre arrow click   */
    event.rexisCount = this.rexisDevicesCount;
    event.s3Count = this.s3DevicesCount;
    if (event?.rowsPerPage === 1) {
      event.rowsPerPage = this.pageParams.rowsPerPage;
      event.page = this.pageParams.page;
    }
    if (this.page !== event?.page || this.rowsPerPage !== event?.rowsPerPage) {
      this.page = event?.page;
      this.rowsPerPage = event?.rowsPerPage;
      this.pageParams.page = this.page;
      this.pageParams.rowsPerPage = this.rowsPerPage;
      this.pageChange.next(this.pageParams);
    }
  }

  public getDevices(event: any): void {
    this.paginatedDevices = [];
    this.loading = false;
    const url = `${this.env}/${this.featureService.msUrl}/${this.countryCode}/devices/?lab_ids=${this.selectedLabIds}`;
    this.subs.push(
      this.featureService.getDevicesWithPagination(url, event).subscribe(
        (response: any) => {
          this.handleResponse(response);
          this.loading = false;
        },
        error => {
          this.handleError(error);
          this.loading = false;
        },
        () => {}
      )
    );
  }

  private handleResponse(response: any): void {
    if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
      this.processSuccessfulResponse(response);
    } else {
      this.paginatedDevices = [];
      //  code to show message box
      this.featureService.notify(response, 'error');
    }
  }

  private processSuccessfulResponse(response: any): void {
    this.paginatedDevices = response?.response?.data; //  All devices section paginated devices
    this.updateDeviceVisibility();
    this.updateDummyDeviceDetails();
    this.updateCounts(response);
    this.updatePageParams();
    this.updateFeatureService();
  }

  private updateDeviceVisibility(): void {
    if (this.showHideView && this.updatedDeviceDetails.length > 0) {
      this.updatedDeviceDetails.forEach(updatedDevice => {
        this.paginatedDevices.forEach(device => {
          if (updatedDevice.serialNo === device.serialNo) {
            device.isVisible = updatedDevice.isVisible;
          }
        });
      });
    }
  }

  private updateDummyDeviceDetails(): void {
    if (this.showHideView) {
      this.dummyDeviceDetails = JSON.parse(JSON.stringify(this.paginatedDevices));
    }
  }

  private updateCounts(response: any): void {
    this.total = Math.ceil(response?.response.total / this.rowsPerPage);
    this.invisibleCount = response?.response?.invisibleCount;
    this.rexisDevicesCount = response?.response?.rexisCount;
    this.s3DevicesCount = response?.response?.s3Count;
    if (this.total === 0) {
      this.total = 1;
    }
    this.allDevicesHidden = this.paginatedDevices.length > 0 && response?.response?.invisibleCount > 0 && this.paginatedDevices.length === response?.response?.invisibleCount;
  }

  private updatePageParams(): void {
    this.pageParams = { page: this.page, rowsPerPage: this.rowsPerPage, total: this.total, rexisDevicesCount: this.rexisDevicesCount, s3DevicesCount: this.s3DevicesCount };
  }

  private updateFeatureService(): void {
    this.featureService.devicePageParams.next(this.pageParams);
    this.featureService.invisibleCount.next(this.invisibleCount);
    if (this.paginatedDevices && this.paginatedDevices.length > 0) {
      this.dummyDeviceDetails = JSON.parse(JSON.stringify(this.paginatedDevices));
      this.featureService.paginatedDevices.next(this.paginatedDevices);
    }
  }

  private handleError(error: any): void {
    console.log('getDevicesFromMulesoft error: ' + error);
    this.featureService.notify(error?.error ? error.error : error, 'error');
    this.paginatedDevices = [];
  }

  public getFirewallStatusOnInterval(self: any): void {
    this.intervalId = this.intervalId ? clearInterval(this.intervalId) : '';
    self.checkIfAllDevicesHidden(true);
    this.dialog.closeAll();
  }

  public isObjectEmpty(): any {
    if (this.firewallData !== undefined && this.firewallData !== null) {
      if (Object.keys(this.firewallData).length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  public getLicenses(): void {
    this.licenseExpired = Object.keys(this.selAccountData?.license).length === 0 || this.selAccountData?.license?.expired === true ? 1 : 0;
    this.firewallLicense = this.licenseExpired === 0 && this.selAccountData?.license?.Firewall?.expired === false ? 1 : 0;
    this.vulnerabilityLicence = this.licenseExpired === 0 && this.selAccountData?.license?.Vulnerabilities?.expired === false ? 1 : 0;
    this.socAndCombinedLicense = this.licenseExpired === 0 && this.selAccountData?.license?.SoC?.expired === false ? 1 : 0;
    this.socLicence = (this.vulnerabilityLicence === 1 && this.firewallLicense === 1) || this.socAndCombinedLicense === 1 ? 1 : 0;
    if ((this.socLicence || this.vulnerabilityLicence) && (this.prevurl.indexOf(this.LAB_DETAIL_PAGE) === -1 || this.paginatedDevices.length === 0)) {
      this.getDevices({ page: 1, rowsPerPage: 10, total: 1, rexisDevicesCount: this.rexisDevicesCount, s3DevicesCount: this.s3DevicesCount });
    }
  }

  // Function to show sbom dedails and vulnerabilities if firewall device selected
  public prepareToShowDetails(device: any, sn: string): void {
    device.device_details.name = device.name ? device.name : device.device_details.name;
    if (sn !== '') {
      device.device_details.serialNo = sn;
    }
    this.showMoreDetails(device.device_details);
  }

  // Function to show sbom dedails and vulnerabilities if one of the device selected
  public showMoreDetails(lab: any): void {
    lab.labId = lab.lab_id ? lab.lab_id : lab.labId;
    const selectedAccountName = this.selectedLabIds.length > 1 ? (lab.labId in this.multiLabNameObj ? this.multiLabNameObj[lab.labId].labName : '') : this.selAccountData.account_name;
    const data = { selectedAccountName, lab };
    this.featureService.deviceData.next(data);
    this.featureService.firewallExpanded.next(this.firewallExpanded);
    this.cancelUpdateDevices();
    this.router.navigate(['app-enabler/lab-detail/' + lab.materialNo]);
  }

  // function to create support ticket
  public createTicket(detail: any, label: string): void {
    const labId = detail?.lab?.labId ? detail.lab.labId : detail?.value?.lab_id;
    const details = { deviceDetails: detail, accountId: labId, contactId: this.authService.userProfile.contactId, name: this.authService.userProfile.name, email: this.authService.userProfile.email, countryCode: this.authService.userProfile.countryCode };
    const data = { details, label };
    this.dialog.closeAll();
    this.dialog.open(SupportTicketComponent, { data });
  }

  public openPopup(details: any): void {
    let generateBtnText = $localize`:@@generate-report-btn:Regenerate`;
    let msg = '';
    if (details?.reportDetails && details?.reportDetails?.modified_date) {
      msg = $localize`:@@download-report-msg:The report you are looking for was generated on ${details.reportDetails?.modified_date}. Click ${generateBtnText} to generate latest report.`;
    } else if (!details?.reportDetails && !details?.reportDetails?.modified_date) {
      generateBtnText = $localize`:@@generate-report-btn:Generate`;
      msg = $localize`:@@download-report-msg:The report you are looking for was not generated before. Click ${generateBtnText} to generate latest report.`;
    }
    if (details?.reportDetails && (details?.reportDetails?.status === 'pending' || details?.reportDetails?.status === 'running')) {
      msg = $localize`:@@download-report-msg:The latest report is being generated. Please try after some time or you can download old report if available.`;
    }
    const data = { title: $localize`:@@download-report:Download report`, body: msg, extraNote: $localize`:@@generate-report-note: Note - Report generation may take few minutes before it\'s available to download.`, closeBtn: $localize`:@@close-btn:Close`, generateReportBtn: generateBtnText, downloadBtn: $localize`:@@download-report-btn:Download`, feature: 'report-download', details };
    this.dialog.open(PopupComponent, { data });
  }

  public showHideDevices($event: any): void {
    this.editVersion = false;
    this.showHideView = true;
    this.dummyDeviceDetails = JSON.parse(JSON.stringify(this.paginatedDevices));
  }

  public editDevices($event: any): void {
    this.editVersion = true;
    this.showHideView = false;
    this.dummyDeviceDetails = JSON.parse(JSON.stringify(this.paginatedDevices));
  }

  public cancelUpdateDevices(): void {
    this.showHideView = false;
    this.editVersion = false;
    this.updatedDeviceDetails = [];

    this.paginatedDevices = this.dummyDeviceDetails;
    this.featureService.paginatedDevices.next(this.paginatedDevices);
  }

  public checkIfAllDevicesHidden(reportStatus: boolean): void {
    let counter = 0;
    this.paginatedDevices.forEach(device => {
      if (device.isVisible === false) {
        counter++;
      }
    });
    if (this.socLicence || this.firewallLicense || this.selAccountData.length > 1) {
      const labIds = this.selAccountData.length > 1 ? this.selAccountData.map(lab => lab.crmNumber) : this.selAccountData.crmNumber;
      this.getFirewallStatus(labIds, reportStatus);
    }

    if (this.paginatedDevices.length > 0 && counter > 0 && counter === this.paginatedDevices.length) {
      this.allDevicesHidden = true;
    } else {
      this.allDevicesHidden = false;
    }
  }

  public onValueChange(event: any, lab: any): void {
    if (event === true || event === 1) {
      lab.isVisible = true;
    } else {
      lab.isVisible = false;
    }
    this.containsObject(lab, this.updatedDeviceDetails);
  }

  public toggleMenuVisibility(): void {
    document.querySelector('owc-menu').visible = !document.querySelector('owc-menu').visible;
  }

  public containsObject(obj: any, list: any): void {
    if (list.length === 0) {
      this.updatedDeviceDetails.push(obj);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i]?.serialNo === obj?.serialNo) {
          this.updatedDeviceDetails.splice(i, 1);
        }
      }
      this.updatedDeviceDetails.push(obj);
    }
  }

  public updateDevices(): void {
    const payload = { userid: `${this.authService.userProfile.contactId}`, devices: this.updatedDeviceDetails };
    this.isDisabled = true;
    const url = `${this.env}/${this.featureService.msUrl}/${this.countryCode}/devices/?lab_ids=${this.selectedLabIds}`;
    this.subs.push(
      this.featureService.updateDevices(url, payload).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.getDevices({ page: this.page, rowsPerPage: this.rowsPerPage, total: this.total, rexisDevicesCount: this.rexisDevicesCount, s3DevicesCount: this.s3DevicesCount });
            this.checkIfAllDevicesHidden(false);
            this.featureService.notify(response, 'success');
          } else {
            this.featureService.notify(response, 'error');
          }
          this.showHideView = false;
          this.editVersion = false;
          this.isDisabled = false;
        },
        error => {
          this.isDisabled = false;
          this.showHideView = true;
          console.log('updateDevices: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        }
      )
    );
  }

  public getFirewallStatus(labIds: any, reportStatus: boolean): void {
    this.clearIntervalId();
    const url = `${this.env}/${this.featureService.fnUrl}/${this.countryCode}/firewall/status/`;
    this.subs.push(
      this.featureService.getFirewallStatus(url, labIds, reportStatus).subscribe(
        response => {
          this.handleStatusResponse(response, reportStatus);
        },
        error => {
          this.handleStatusError(error);
        }
      )
    );
  }

  private handleStatusResponse(response: any, reportStatus: boolean): void {
    if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
      if (reportStatus) {
        this.processReportStatus(response);
      } else {
        this.firewallData = response?.response?.data; // Main firewall status data
      }
      this.featureService.firewallData.next(this.firewallData);
    } else {
      this.firewallData = {};
      this.featureService.notify(response, 'error');
    }
  }

  private processReportStatus(response: any): void {
    const firewallReportStatusData = response?.response?.data;
    const subkey = 'report_status';
    let fnReportStatus = false;

    Object.entries(this.firewallData).forEach(([key, value]) => {
      Object.entries(firewallReportStatusData).forEach(([serialno, rStatus]) => {
        if (key === serialno) {
          value[subkey] = rStatus?.[subkey];
        }
        if (rStatus?.[subkey]?.status === 'running' || rStatus?.[subkey]?.status === 'pending') {
          fnReportStatus = true;
        }
      });
    });

    this.handleIntervalId(fnReportStatus);
    this.dialog.closeAll();
  }

  private handleIntervalId(fnReportStatus: boolean): void {
    if (fnReportStatus === true) {
      this.clearIntervalId();
      this.intervalId = setInterval(this.getFirewallStatusOnInterval, 30000, this);
    } else if (fnReportStatus === false) {
      clearInterval(this.intervalId);
    }
  }

  private clearIntervalId(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = '';
    }
  }

  private handleStatusError(error: any): void {
    console.log('getFirewallStatus: ' + error);
    this.featureService.notify(error?.error ? error.error : error, 'error');
  }
}
