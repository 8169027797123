 <!--  Firewall Pie Chart-->
 <div id="top-container" class="top-container">
    <div id="main-page" class="container" style="background: #fff;">
      <owc-card id="overview-feature-card" flat scroll-shadow class="owc-card" [ngClass]="{  activate:  true }">
        <div slot="header" class="header-section">
          <div class="row">
            <div id="overview-feature-icon" class="zero">
              <owc-icon #iconElement name="security" type="legacy" class="mat-header-icon" [style]="{ color: 'blue' }"> </owc-icon>
            </div>
            <div class="two" id="overview-feature-name" >
              <h3  title="title" style="margin: 0px 3px; word-break: break-all;position: absolute;">Firewall protection</h3>
            </div>
            <div id="overview-feature-desc" class="three " [style]="{ display: 'grid' }">
              In this area, you will receive an overview of the status of your firewall and have access to your firewall report.</div>
          </div>
        </div>
        <dl-doughnut-chart  [firewallPieData]="firewallPieData" *ngIf=" this.totalFirewallDevices > 0; else loader" ></dl-doughnut-chart>
        <ng-template #loader>
          <div *ngIf="!(this.firewallPieData.length > 0)" class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-template>
        
        <div style="text-align: center;" *ngIf=" this.totalFirewallDevices <= 0 &&  !this.loading ">
          <div><owc-icon name="notification_warning" family="outlined"></owc-icon></div>
          <div><span i18n="@@firewall-tile-empty-msg">No firewall device(s) found</span></div>
        </div>
        <div slot="footer" *ngIf=" this.totalFirewallDevices > 0 &&  !this.loading " style="margin-top: auto;">
          <a target="_blank" [href]="viewStatusUrl">
            <owc-button  variant="secondary" style="float: right"  i18n="@@view-status-btn"> View status</owc-button>
          </a>
        </div>
      </owc-card>
  </div>
 </div>