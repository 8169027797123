import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRouterModule } from './app-router.module';
import { AppComponent } from './app.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { OneWebComponentsAngularModule } from '@one/angular';
import { CoreModule } from './core/core.module';
import { SampleFeatureModule } from './features/sample-feature/sample-feature.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, HammerModule, BrowserAnimationsModule, AppRouterModule, OneWebComponentsAngularModule, CoreModule.forRoot(), SampleFeatureModule, FormsModule, ReactiveFormsModule],
  providers: [ { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }, ...environment.providers],
  bootstrap: [AppComponent]
})
export class AppModule {}
