import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { Chart, ChartEvent } from 'chart.js';

@Component({
  selector: 'dl-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
  public chart: any;
  @Input() public firewallPieData: any = [];
  @Input() public devicesPieData: any = [];
  @Input() public devicesChart: boolean;
  @Input() public sdsPieData: any = [];
  @Output() chartClick = new EventEmitter<any>();

  public ngOnInit(): void {
    setTimeout(() => {
      if (this.devicesPieData && this.devicesPieData.length > 0) {
        this.createDoughnutChart('devicePieChart', this.devicesPieData);
      }

      if (this.firewallPieData && this.firewallPieData.length > 0) {
        this.createDoughnutChart('firewallPieChart', this.firewallPieData);
      }
      if (this.sdsPieData && this.sdsPieData.length > 0) {
        this.createDoughnutChart('sdsPieChart', this.sdsPieData);
      }
    }, 10);
  }

  public createDoughnutChart(elem: string, chartData: any): void {
    this.chart = new Chart(elem, {
      type: 'doughnut', // this denotes tha type of chart

      data: {
        labels: chartData.map(item => item.name),
        datasets: [
          {
            label: 'My First Dataset',
            data: chartData.map(item => item.value),
            backgroundColor: chartData.map(item => item.color),
            hoverOffset: 4
          }
        ]
      },
      options: {
      //  aspectRatio: 2,
      //  responsive: true,
       maintainAspectRatio: false,
        legend: {
          display: this.devicesPieData.length < 8,
          position: 'right',
          labels: {
            usePointStyle: true
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const firstElement = elements[0];
            const clickedIndex = firstElement._index;
            console.log(clickedIndex);
            // You can also get the specific data if required
            const clickedData = chartData[clickedIndex];
            console.log(clickedData);
            // Add your click handling logic here
            if (elem === 'sdsPieChart') {
              this.chartClick.emit(clickedIndex);
            }
            //  alert(`You clicked on ${clickedData.name} with value ${clickedData.value}`);
          }
        }
      }
    });
  }
}
