import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
export interface AppVersionInterface extends EnvironmentInterface {
  appVersion: string;
  marketplaceUrl: string;
  walkmeUrl: string;
  softwareDistributionUrl: string;
}

export const environment: AppVersionInterface = {
  production: false,
  appUrlId: 'dialog',
  featureFlags: { groups: false, favorites: false, getHelp: true, welcomePopup: false, apiCallSSO: true },
  apiUrl: '',
  appVersion: require('../../../../package.json').version,
  googleAnalyticsCode: '',
  dialogUrl: 'rexis-dialog-uat.roche.com',
  appUrl: '',
  hubUrl: 'https://esrv-hub-uat.roche.com/',
  allOtherCountriesUrl: 'https://pim-eservices.roche.com/eLD/web/pi/en/home',
  marketplaceUrl: 'https://marketplace-uat.roche.com',
  softwareDistributionUrl: 'https://uat.softwaredistribution.navify.com',
  walkmeUrl: 'https://eu-cdn.walkme.com/users/bee54912a4ab4d61993e428b5d157654/dev/walkme_bee54912a4ab4d61993e428b5d157654_https.js',
  orgId: '00D1w0000000WQD',
  recordTypeId: '012b0000000M2qA',
  groupsPageLimit: 20,
  cognito: {
    domain: 'auth.esrvsecsuite.navify.com',
    clientId: '2ou1dh0jp0ra49m3u954kgu12b',
    redirectSignInUrl: '',
    redirectSignOutUrl: '',
    userPoolId: 'eu-central-1_bjOIgA4l2',
    samlIdP: 'Dialog'
  },
  contactIdPlaceholder: 'PATH_CONTACT_ID',
  providers: [],
  availableLocales: {
    au: { en_au: false, en_us: false },
    ar: { es_ar: false },
    ae: { en_us: false },
    bg: { bg: false },
    ca: { en_ca: true, fr_ca: true },
    cl: { es: false, es_cl: false },
    co: { es: true, es_co: true },
    be: { en_us: true, fr_be: true, nl_be: true },
    br: { pt_br: true, en_us: true, es: true },
    dk: { da: true },
    cz: { cs: true },
    de: { de: true, en_us: true },
    at: { de_at: true, en_us: true },
    ch: { de_ch: true, fr_ch: true, it_ch: true, en_us: true },
    us: { en_us: true },
    gb: { en_gb: true },
    gt: { es_gt: false },
    za: { en_za: false },
    ee: { et: false, en_us: false },
    eg: { en_us: false },
    es: { es: true },
    fi: { fi: true, en_us: true },
    fr: { fr: true, en_us: true },
    hr: { hr: true, en_us: true },
    hu: { hu: true, en_us: true },
    id: { en_us: false },
    it: { it: true, en_us: true },
    is: { en_us: false },
    in: { en_gb: false },
    jp: { ja: false, en_us: false },
    kr: { ko: false, en_us: false },
    lt: { lt: true, en_us: true },
    lv: { lv: true, en_us: true },
    lu: { en_us: false, fr: false, de: false },
    mt: { en_us: false },
    my: { en_us: false },
    mm: { en_us: false },
    mx: { es_mx: true },
    nz: { en_us: false },
    nl: { nl_nl: true, en_us: true },
    no: { no: true, en_us: true },
    pk: { en_us: false },
    pl: { pl: true },
    pa: { es_pa: false, en_us: false },
    pe: { es_pe: false },
    ph: { en_us: false },
    pi: { en_us: false },
    pt: { pt_pt: true },
    ro: { ro: true, en_us: true },
    ru: { ru: true, en_gb: true },
    sa: { en_us: false },
    sg: { en_us: false },
    sk: { sk: true, en_us: true },
    sl: { sl: true },
    se: { sv: true, en_us: true },
    si: { sl: true, en_gb: true },
    th: { th: false, en_us: false },
    tr: { tr: false, en_us: false, ru: false },
    tw: { zh_tw: false, en_us: false },
    ua: { en_us: false },
    uy: { es_uy: false },
    vn: { vi: false, en_us: false },
    ve: { es: false },
    gr: { el: true, en_us: true },
    cn: { en_us: false, zh_cn: false, zh_tw: false },
    hk: { en_us: false },
    ec: { es_ec: true },
    cy: { el: true },
    xo: { en_us: false }
  },
  redirectAffiliate: {
    al: 'fr',
    bf: 'fr',
    yt: 'fr',
    bj: 'fr',
    cm: 'fr',
    cv: 'fr',
    cf: 'fr',
    td: 'fr',
    cd: 'fr',
    cg: 'fr',
    gf: 'fr',
    ci: 'fr',
    dj: 'fr',
    eh: 'fr',
    gq: 'fr',
    ga: 'fr',
    gm: 'fr',
    gn: 'fr',
    gw: 'fr',
    gp: 'fr',
    lr: 'fr',
    lu: 'fr',
    ml: 'fr',
    mq: 'fr',
    mr: 'fr',
    mc: 'fr',
    mg: 'fr',
    ma: 'fr',
    ne: 'fr',
    re: 'fr',
    st: 'fr',
    sn: 'fr',
    sl: 'fr',
    tg: 'fr',
    tn: 'fr',
    ie: 'gb',
    ad: 'es',
    az: 'tr',
    ge: 'tr',
    kz: 'tr',
    kg: 'tr',
    tj: 'tr',
    tm: 'tr',
    uz: 'tr',
    bh: 'ae',
    ir: 'ae',
    iq: 'ae',
    jo: 'ae',
    kw: 'ae',
    lb: 'ae',
    ly: 'ae',
    mv: 'ae',
    om: 'ae',
    ps: 'ae',
    qa: 'ae',
    sy: 'ae',
    ye: 'ae',
    ba: 'hr',
    ag: 'pa',
    aw: 'pa',
    bs: 'pa',
    bb: 'pa',
    bz: 'pa',
    bm: 'pa',
    bq: 'pa',
    gd: 'pa',
    ht: 'pa',
    sr: 'pa',
    vg: 'pa',
    ky: 'pa',
    cr: 'pa',
    cu: 'pa',
    cw: 'pa',
    dm: 'pa',
    do: 'pa',
    sv: 'pa',
    gy: 'pa',
    hn: 'pa',
    jm: 'pa',
    ni: 'pa',
    tt: 'pa',
    tc: 'pa',
    kn: 'pa',
    vc: 'pa',
    bo: 'uy',
    py: 'uy',
    bw: 'za',
    bi: 'za',
    er: 'fr',
    et: 'za',
    gh: 'za',
    ke: 'za',
    ls: 'za',
    mw: 'za',
    mu: 'za',
    mz: 'za',
    na: 'za',
    ng: 'za',
    rw: 'za',
    so: 'za',
    sd: 'za',
    sz: 'za',
    ug: 'za',
    zm: 'za',
    zw: 'za',
    tz: 'za',
    li: 'ch',
    kh: 'th'
  },
  apiProcessSuffix: '/process',
  apiUserSysSuffix: '/users-sys',
  qualtricsConfig: {
    delay: 15000,
    windowVariableName: 'qualtricsDelay',
    windowVariableValue: true
  }
};
