import { Injectable } from '@angular/core';
import { fromEventPattern, Observable } from 'rxjs';
import { NodeEventHandler } from 'rxjs/internal/observable/fromEvent';
import { map, pairwise, startWith } from 'rxjs/operators';
import { OneTrust } from '../models/oneTrust';
import { ConsentEvent } from '../models/consentEvent';

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {
  // OneTrust loaded instance
  get oneTrust(): OneTrust {
    return (window as any).OneTrust;
  }

  // will emit the accepted categories array based on the user selection
  get consentChanged$(): Observable<string[]> {
    return this.fromConsentChanged(this.oneTrust);
  }

  // will emit only if the user has declined at least one cookie category
  get cookiesDeclined$(): Observable<boolean> {
    return this.consentChanged$.pipe(
      startWith<Array<string>, Array<string>>([]),
      pairwise(),
      map(([prev, actual]) => this.areCookiesDeclined(prev, actual))
    );
  }

  constructor() {}

  // call it once to reload the page after at least one cookie has been declined
  reloadOnCookiesDeclined(): void {
    // Reload the page, to deactivate AdobeAnalytics tracking.
    // When the page is reloaded, all scripts have type="text/plain" and only the allowed once
    // are changed by the OneTrust script to type="text/javascript"
    this.cookiesDeclined$.subscribe(() => location.reload());
  }
  // call it once to reload the page if the cookie consent changes
  reloadOnConsentChanged(): void {
    // Reload the page, to deactivate AdobeAnalytics tracking.
    // When the page is reloaded, all scripts have type="text/plain" and only the allowed once
    // are changed by the OneTrust script to type="text/javascript"
    this.oneTrust.OnConsentChanged(() => location.reload());
  }

  // shortcut for opening the Cookie Consent Panel
  openCookiePreferences(): void {
    this.oneTrust.ToggleInfoDisplay();
  }

  closeCookiePreferences(): void {
    let e = new Event('click');
    this.oneTrust.Close(e);
  
  }

  private fromConsentChanged(oneTrustInstance: OneTrust): Observable<Array<string>> {
    return fromEventPattern((handler: NodeEventHandler) => oneTrustInstance.OnConsentChanged(handler)).pipe(
      map((event: ConsentEvent) => event.detail),
      startWith<Array<string>, Array<string>>(this.oneTrustActiveGroups())
    );
  }

  private oneTrustActiveGroups(): Array<string> {
    return ((window as any).OnetrustActiveGroups as string).split(',').filter(Boolean);
  }

  private areCookiesDeclined(prev: Array<string>, actual: Array<string>): boolean {
    return prev && prev.length > 0 && actual.length < prev.length;
  }

  private areGroupsEquals(prev: Array<string>, next: Array<string>): boolean {
    // different sizes means something changed
    if (prev.length !== next.length) {
      return false;
    }
    // check if categories from next are the same as categories from prev
    return next.every((category: string) => !prev[category]);
  }
}
